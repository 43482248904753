@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@200;300;400;500;600&display=swap');

:root {
  --themeclr: #F6DF2D;
  --textclr: #DEC508;

  /* --navhover: #C766FC; */
  /* --greens: #74E842; */
  --backcolor: #0c0c0c;
  --whites: #fff;

  --navhover:#FF67FF;
  --greens:#00D1FF;
  --lighttxt:#9C9C9C;

  --genhover:#57fff1;
}
/* tryourbest */
.faq .accordion-collapse {
  height: 14px;
  overflow: hidden;
  background-position-y: bottom !important;
  background-size: cover !important;
  margin-left: 2%;
  transition: 0.5s;
  margin-right: 1%;
  /* margin-top: -2%; */
}
.faq .accordion-collapse.collapse.show {
  /* height: 296px;
  min-height: 296px;
  max-height: 296px; */

  height: 490px;
  min-height: 490px;
  max-height: 490px;

  overflow: hidden;
  background-position: center !important;
  background-size: 100% 100% !important;
  margin-left: 2%;
  transition: 0.5s;
  margin-top: -2%;
  overflow-y: auto;
}
.viewdetail{
  height: 350px;
  min-height: 350px;
  max-height: 350px;
  overflow-y: auto;
}
.viewdetail::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.viewdetail::-webkit-scrollbar
{
	width: 11px;
	background-color: #F5F5F5;
}

.viewdetail::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}
.faq .collapse:not(.show){
  display: block;
}
/* tryourbest */



a {
  text-decoration: none !important;
}

.no-bord {
  border: 0 !important;
}


/* genesis css */
.bannerlocked{
  min-height: 110px !important;
}
.logohome{
  /* background: url("./Assets/genesis/genesislogo.gif") no-repeat; */
  /* background-size: 100% 100%;
  background-position: center; */
  height: 100vh;
  filter: grayscale(1);
  position: relative;
  z-index: 4;
}
.genesisimage{
  position: absolute;
  z-index: -1;
}

.alllist{
  background: url("./Assets/genesis/landingpageback.png");
  background-size: 100% 100%;
  background-position: center;
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.footers {
  position: relative;
  z-index: 1;
}

.footers::before{
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  opacity: .2;
  background: url("./Assets/genesis/footer_bg.gif") no-repeat !important;
  background-color: #000 !important;
  background-size: 100% 100% !important;
}
.alllist.alllist1{
   padding: 6% 0% !important;
}
.alllist {
  padding: 6% 0%;
}
.alltitle{
  position: relative;
  z-index: 5;
}
.launchpadall li.nav-item button.active, .launchpadall li.nav-item button:hover{
  background: url("./Assets/genesis/live\ button.png") no-repeat !important;
  background-size: 100% 100% !important;
  border-bottom: #000 !important;
  color: #fff !important;
}
.h1tag{
  font-size: 46px;
  font-weight: 700;
  text-align: center;
  color: #fff;
}
.alltitle .lefthead{
  position: absolute;
  left: 0;
  top: 50%;
  width: 24%;
  z-index: -1;
}
.alltitle .righthead{
  position: absolute;
  right: 0;
  top: 50%;
  width: 24%;
  z-index: -1;
}
.concept .blkbtn{
  background: url("./Assets/genesis/blkbutton.png") !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  background-position: center !important;
}
.concept .userbtn{
  padding: 0px 40px !important;
}
.lighttxt{
 color: var(--lighttxt);
 font-size: 15px;
}
/* .funds img{
    width: 12vw;
    max-height: 8vw;
    object-fit: contain;
    aspect-ratio: 3/2;
  } */
  .modalheading{
    position: relative;
    z-index: 6;
  }
  .leftarrows{
    position: absolute;
    left: 0;
    top: 25%;
    z-index: -1;
  }
  .connect img{
    width: 45px;
    height: 45px;
  }
.connect {
    border-bottom: 1px solid #ffffff7a;
  }
  .btn-link.themeclr .fa-times{
    color: #fff;
  }
  .btn-link.themeclr .fa-times:hover{
    color: var(--genhover) !important;
  }
  .rightarrows{
    position: absolute;
    right: 0;
    top: 25%;
    z-index: -1;
  }
  .overalltab{
    border: 1px solid #ffffffb3;
    overflow-x: auto;
  }
  .overalltab ul.nav-tabs{
    padding-top: 25px !important;
    background-color: #404040 !important;
    padding-bottom: 0px !important;
    max-width: 100% !important;
    overflow-x: unset !important;
  }
  .overalltab li.nav-item{
    background-color: #404040 !important;
  }
  .overalltab .nav-link{
     padding: 14px 16px !important;
     text-align: left !important;
  }
  .overalltab li.nav-item button:hover {
    color: #fff !important;
    border-bottom: unset !important;
    background-color: #404040 !important;
  }
  .closes{
    color: #929292;
  }
 .tab-pane{
   min-height: 350px;
 }
  .copybtn svg{
  font-size: 30px;
  color: #fff;
  }
  .overalltab li.nav-item button.active .closes {
    color: #fff;
  }
  .overalltab li.nav-item button.active {
    color: #fff !important;
    border-bottom: unset !important;
    background-color: #000 !important;
    border: 1px solid #ffffff87 !important;
    border-bottom-color: #000 !important;
  }
 .launchpad.allitems .tab-content .tab-pane{
    font-size: 15px;
    padding: 20px;
    background-color: #181818;
  }
  .launchpad  .tab-content .tab-pane{
    padding: 0px;
  }
/* .logoss{
  width: 440px;
  height: 140px;
  object-fit: contain;
} */
.footers ul{
  padding-left: 0px;
  list-style-type: none;
}
.footers li a{
  color: #fff;
  font-size: 15px;
  transition: 0.5s;
}
.footers li a:hover, .terms a:hover{
  color: var(--genhover);
  transition: 0.5s;
}
.footers li {
  margin-bottom: 10px;
}
.logosection{
  width: 100%;
}
.socialmedias a{
  margin-left: 20px;
}
.socialmedias a img:hover{
  filter: drop-shadow(2px 4px 6px #57fff1);
}
.fundss img:hover{
  filter: drop-shadow(2px 5px 14px #ffffff69);
}
.partnerimg{
  width: 150px !important;
  height: 50px;
  object-fit: contain;
}
.terms a{
  margin-left: 20px;
  color: #fff;
  font-size: 14px;
  transition: 0.5s;
}
.owl-prev{
  background: url("./Assets/genesis/leftarr.svg") no-repeat !important;
  background-size: 100% 100%;
}
.owl-prev:hover .fa-angle-left, .owl-next:hover .fa-angle-right {
    color: var(--genhover);
}
.owl-next{
  background: url("./Assets/genesis/rightarr.svg") no-repeat !important;
  background-size: 100% 100%;
}
 .offcanvas.offcanvas-end{
 background-color: #000 !important;
 width: 600px;
}
.offcanvas.offcanvas-end .offcanvas-body{
  font-size: 14px;
  position: relative;
  z-index: 6;
}
.offcanvas.offcanvas-end .offcanvas-body .btn-dark{
  position: absolute;
  width: 90% !important;
  margin: auto;
  transform: translate(-50%, -50%);
  bottom: 0;
  left:50%;
}
.allsection .owl-prev .fa-angle-left{
   width: 60px;
   height: 60px;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-top: -4px;
} 
.allsection .owl-next .fa-angle-right{
  width: 54px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -4px;
} 
.terms a:nth-child(2){
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  padding: 0px 14px;
}
.footers .copyright{
  font-size: 13px;
}
.faq .accordion-body{
  /* padding: 30px 0px; */
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 40px;
  padding-bottom: 50px;
}
.faq .accordion-body .row{
   padding-left: 20px;
   padding-right: 20px;
}
.faq .accordion-body p{
  color:#BEBDBD;
}
.faq .viewdetail{
  border: 1px solid gray;
  padding: 16px;
  word-wrap: break-word;
}
.accordion-button::after{
  background: url("./Assets/genesis/down\ arrow.png") no-repeat !important;
  background-size: 1rem !important;
  /* margin-top: -12px; */
}
.accordion-button:not(.collapsed)::after {
  /* margin-top: -26px; */
}
.faq .accordion-button{
  background: url("./Assets/genesis/rect.png") no-repeat;
  /* background-color: black; */
  color: #fff;
  background-size: 100% 100%;
  background-position: center;
  padding-left: 3%;
  height: 65px;
  min-height: 65px;
  max-height: 65px;
}
.faq .accordion-header{ 
  margin-top: -3px;
}

.faq .accordion-collapse{
  background: url("./Assets/genesis/bottom-acc.png") no-repeat;
  background-color: black;
  color: #fff;
  background-size: 100% 100%;
  /* margin-left: 2%;
  margin-top: -5%; */
}
.faq .accordion-button::after{
  /* display: none; */
}
.faq .accordion-button:focus{
  box-shadow: unset;
}
.faq .accordion-item{
  border: 1px solid transparent;
  background-color: transparent;
  margin-top: 0%;
}
.numberbox{
  background: url("./Assets/genesis/number\ box.png") no-repeat;
  background-size: 100% 100%;
  background-position: center;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.fw-600{
  font-weight: 600;
}
.fullimg{
  background: url("./Assets/genesis/3in1.gif") no-repeat;
  background-size: 100% 100%;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.tab-content p{
  word-wrap: break-word;
}
.h-100vh{
  height: 100vh;
}

@media screen and (max-width: 1100px) and (min-width: 992px) {
  .header .navbar-nav a{
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
}
@media screen and (max-width: 1199px) and (min-width: 992px) {
  .alltitle .righthead,.alltitle .lefthead{
    width: 20%;
  }
}



@media(max-width:991px) {
  .faq .accordion-collapse{
    background: unset !important;
    border: 1px solid #fff !important;
    background-color: #363636 !important;
  }
  .faq .accordion-collapse.collapse.show {
 overflow-y: auto;
  }
  .leftarrows,.rightarrows{
    display: none;
  }
  .header .navbar-nav a:hover:after,  .header .navbar-nav a.active:after {
    left: -10px !important; 
  }
  .header .navbar-nav a:hover:before,  .header .navbar-nav a.active::before {
    right: -10px !important; 
   }
   .alltitle .righthead,.alltitle .lefthead{
    display: none;
  }
  .faq .accordion-collapse.collapse.show {
    height: 390px;
    min-height: 390px;
    max-height: 390px;
  }
}

@media(max-width:767px) {
  .overalltab ul.nav-tabs{
    max-width: 100% !important;
    overflow-x: auto !important;
    padding-bottom: 10px;
  }
  .overalltab ul.nav-tabs::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.overalltab ul.nav-tabs::-webkit-scrollbar
{
	width: 6px;
  height: 6px;
	background-color: #F5F5F5;
}

.overalltab ul.nav-tabs::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}
  .alllist{
    height: unset;
  }
  .faq .accordion-collapse{
    background: unset !important;
    border: 1px solid #fff !important;
    background-color: #363636 !important;
  }
  .faq .accordion-collapse.collapse.show {
 overflow-y: auto;
  }
  .h1tag{
    font-size: 35px;
  }
}

@media(max-width:575px) {
  .fullimg{
    background: url("./Assets/genesis/3in1.gif") no-repeat;
    background-size: contain !important;
    background-position: center !important;
  }
  .logocont img{
    height: 52px !important;
    width: 107px !important;
  }
  .logocont span{
    font-size: 14px !important;
  }
  .logohome{
    /* height: 54vh; */
  }
  .h1tag{
    font-size: 26px;
  }
  .lockedtitle{
    font-size: 24px !important;
  }
  .lighttxt{
    font-size: 14px;
  }
  .accordion-button::after{
    margin-top: 10px;
  }
  .numberbox{
    height: 34px;
    font-size: 14px;
  }
  .btn-dark{
   font-size: 13px; 
  }
}

 /* genesis end */











body {
  background-color: #000 !important;
  /* background-color: var(--backcolor); */
  color: #fff;
  font-family: 'Space Mono', monospace;
  scroll-snap-align: start;
  scroll-behavior: smooth;

  /* background: repeating-linear-gradient(
    0deg,
    #0E0D0E 25%,
    #0E0D0E 50%,
    #171819 50%,
    #171819 75%
  );
background-size: 20px 20px;
height: 100vh; */
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: black;
}

body::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  /* background-color: #74E842; */
  background-image: linear-gradient(#fff, #fff);
}

/* @font-face {
  font-family: themefont;
  src: url(./Assets/Font/Gilroy-Black.ttf);
}
@font-face {
  font-family: thin;
  src: url(./Assets/Font/Gilroy-Thin.ttf);
}
@font-face {
  font-family: bold;
  src: url(./Assets/Font/Gilroy-Bold.ttf);
}
@font-face {
  font-family: extrabold;
  src: url(./Assets/Font/Gilroy-ExtraBold.ttf);
}
@font-face {
  font-family: medium;
  src: url(./Assets/Font/Gilroy-Medium.ttf);
}
@font-face {
  font-family: normal;
  src: url(./Assets/Font/Gilroy-Regular.ttf);
}
@font-face {
  font-family: light;
  src: url(./Assets/Font/Gilroy-Light.ttf);
} */
.container.container-theme {
  max-width: 95%;
}

.normal {
  /* font-family: normal; */
}

.bold {
  /* font-family: bold; */
}

.innerheading {
  /* font-family: extrabold; */
}

.themeclr {
  color: var(--greens) !important;
}

.textclr {
  /* color: var(--greens); */
  color: #fff;
}

.flex-1>* {
  flex: 1;
}

.jc-between {
  justify-content: space-between;
}

.br-14 {
  border-radius: 14px;
}

.bgtheme {
  /* background-color: var(--themeclr); */
}

button.ovalbtn {
  /* background: #17a2c1; */
  /* border-radius: 30px; */
  /* padding: 4px 10px; */
  font-weight: 600;
  /* border: 1px solid #17a2c1; */
  color: #000;
  background: url("./Assets/genesis/stopped.svg") no-repeat !important;
  background-size: 100% 100% !important;
  border: 0;
  padding: 6px 16px;
}
.btn.getstarted {
  background: var(--themeclr);
  font-family: 'extrabold';
  border-radius: 0;
  position: relative;
  min-width: 120px;
  padding: 10px 20px;
}

.launchpad .launchbox .btn.getstarted,
.lockedstake .launchbox .btn.getstarted {
  background-color: #4008DE;
  color: #fff;
}

.launchpad .innerheading,
.lockedstake .innerheading {
  color: var(--whites);
}

.smallp {
  max-width: 50%;
  margin: auto;
  color: #cbcbcb;
}
.btn:focus {
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
}
button.chocobtn {
  background: url("./Assets/genesis/stopped.svg") no-repeat;
  background-size: 100% 100%;
  font-size: 16px;
  padding: 8px 16px;
  font-weight: 600;
  color: #000 !important;
  border: 0;
}
.lockedstackss .chocobtn{
  background: url("./Assets/genesis/live\ button.png") no-repeat !important;
  background-size: 100% 100% !important;
  color: #fff !important;
}
.lockedstackss .chocobtn:hover{
  color: var(--genhover) !important;
}

button.btn.getstarted.dark {
  background: #000;
  color: #fff;
}

button.btn.getstarted:after {
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid white;
  position: absolute;
  left: -5px;
  bottom: 4px;
}

section {
  /* margin-top: 65px; */
  /* margin-top: 100px; */
  padding-top: 100px;
  /* margin-bottom: 75px; */
  padding-bottom: 75px;
}

button.btn.getstarted:after {
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid white;
  position: absolute;
  left: -5px;
  bottom: 4px;
  transition: 0.2s;
}

.ban-cont p,
.features-list p {
  font-family: normal;
  color: #CBCBCB;
  line-height: 2;
}

.btn.btn-theme {
  background-color: var(--textclr);
}

/* header */
.land .navbar-nav .getstarted {
  display: none;
}

.header {
  position: fixed;
  width: 100%;
  top: 0;
  /* border-bottom: 1px solid #80808061; */
  z-index: 777;
}

.header-content {
  /* justify-content: center; */
  justify-content: space-evenly;
}

.userbtn {
  background: url("./Assets/genesis/live\ button.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  color: #fff;
  min-width: 190px;
  min-height: 50px;
  /* text-transform: uppercase; */
  font-size: 14px;
  font-weight: 500;
  padding: 0px 16px;
  /* animation: glow 1s infinite alternate; */
}
.userbtn.small{
  min-width: 60px;
  min-height: 50px;
  font-size: 24px;
}
.userbtn:hover {
  color: #c766fc !important;
}

.headingbtn {
  /* color: #64C839; */
  /* color: var(--greens); */
  color: #fff;
  font-weight: 500;
 transition: 0.5s;
}

.headingbtn:hover{
  /* color: var(--greens) !important; */
  color: var(--genhover) !important;
  /* filter: drop-shadow(2px 4px 6px #00d1ff33); */
  transition: 0.5s;
}
.header .bg-dark {
  background: #000 !important;
}

.header .navbar-nav a:hover,
.header .navbar-nav a.nav-link.active {
  /* border-bottom: 2px solid var(--greens); */
  background: url("./Assets/genesis/underline.png") no-repeat;
  /* background-size: 100px; */
  background-size: 150px 4px;
  background-position-y: bottom;
}
.header .navbar-nav a:hover:after,  .header .navbar-nav a.active:after {
  content: "{";
  position: absolute;
  left: -3px; 
  color: #fff !important;
}
.header .navbar-nav a:hover:before,  .header .navbar-nav a.active::before {
  content: "}";
  position: absolute;
  right: -3px; 
  color: #fff !important;
 }




.navbar-dark .navbar-nav .nav-link {
  font-weight: 300;
  position: relative;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: var(--genhover) !important;
}

.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show>.nav-link {
  color: var(--genhover) !important;
  /* color: #fff !important; */
}

.header .navbar-nav a {
  border-bottom: 3px solid transparent;
  margin-left: 20px;
  margin-right: 20px;
}


.header .navbar-nav a:last-child {
  margin-right: 0;
}

.header .navbar-nav a:first-child {
  margin-left: 0;
}

.lockedstake nav.navbar .btn.getstarted,
.launchpad nav.navbar .btn.getstarted {
  max-width: 150px;
}

/* tabs */
ul.nav-tabs {
  max-width: 435px;
  flex-flow: nowrap;
  overflow-x: auto;
  padding-bottom: 15px;
}

ul.nav-tabs li {
  flex: 1;
  white-space: nowrap;
}

li.nav-item button {
  background: transparent !important;
  border-color: transparent !important;
  color: #fff;
  width: 100%;
}

li.nav-item button:hover {
  /* color: #fff !important; */
  color: var(--navhover) !important;
  border-bottom: 2px solid var(--greens) !important;
}

li.nav-item button.active {
  /* color: #000 !important; */
  /* background-color: #f6df2d !important; */
  /* border-radius: 5px; */
  color: var(--navhover) !important;
  border-bottom: 2px solid var(--greens) !important;
  /* background-image: linear-gradient(#74E842,#c16bf0) !important; */
  /* background: url("./Assets/images/btn-bg.png") !important;
  background-repeat: no-repeat;
  background-size: 100% 100% !important;
  background-position: center;
  min-width: 130px;
  min-height: 46px; */
}

ul.nav-tabs {
  border: 0 !important;
}


.news {
  position: relative;
}

/* .newshead{margin-top:-24px;} */
.news .items {
  position: relative;
  height: 90vh;
}

.news .itemsfles {
  display: flex;
  align-items: start;
  flex-direction: column;
  max-height: 100vh;
  justify-content: center;
  width: 50%;
  height: 100%;
  min-height: 80vh;
  position: absolute;
  bottom: 0;
  left: 41px;
  right: 0;
  top: 0;
  background: #00000080;
  padding: 8px;
}

.news .itemsfles h2 {
  font-size: 50px;
  font-weight: 600;
}

.news .itemsfles p {
  font-size: 16px;
  font-weight: 400;
}

.news .owl-carousel .owl-nav {
  position: absolute;
  bottom: 50px;
  right: 80px;
}

.news .owl-carousel .owl-nav button.owl-next {
  background-color: #ffffff80;
  color: #5e5e5e;
  font-size: 28px;
  /* border-radius: 50px;
  width: 42px; */
  width: 60px;
  height: 50px;
}

.news .owl-carousel .owl-nav button.owl-prev {
  /* background-color: #ffffff80;
      color: #5e5e5e; */
  background-color: var(--greens);
  color: #ffff;
  font-size: 28px;
  /* border-radius: 50px; */
  /* width: 42px; */
  width: 60px;
  height: 50px;
}

.news .owl-carousel .owl-nav button.owl-prev:hover,
.news .owl-carousel .owl-nav button.owl-next:hover {
  /* background-color: #fff; */
  background-color: var(--greens);
    color: var(--genhover);
}

.Editor_pick {
  cursor: pointer;
}

.Editor_pick h2 {
  color: #fff;
  /* font-family: extrabold; */
  padding: 20px 0;
  font-weight: 600;
}

.col_token:nth-child(even) .token_list {
  /* background-color: #DEC508;
  border: 1px solid #DEC508; */
  background-color: transparent;
  /* border: 1px solid #fff; */
  border-radius: 8px;
  margin-top: 20px;
  transition: all 0.5s ease;
  margin-bottom: 6px;
}

.token_list {
  /* background-color: #fff; */
  position: relative;
  overflow: hidden;
  /* background-color:transparent; */
  /* border: 1px solid #fff; */
  background: url("./Assets/genesis/locked_back.png") no-repeat;
  background-size: 100% 100%;
  padding-left: 14px;
  padding-right: 14px;
  /* border-radius: 8px; */
  margin-top: 20px;
  transition: all 0.5s ease;
  margin-bottom: 6px;
  transition: all .3s ease;
  /* background: url("./Assets/images/tablebg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%; */
}
.token_list:hover{
  transform: translateY(-10px);
}
.token_list span {
  position: absolute;
  border-radius: 100vmax;
}
.tokenimg {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 220px;
  max-height: 220px;
  overflow: hidden;
}

.tokenimg img {
  /* min-height: 250px;
  object-fit: cover;
  max-width: 100%; */
  min-height: 200px;
  object-fit: cover;
  max-width: 94%;
  /* margin-top: 20px; */
  margin: auto;
  margin-top: 4px;
  transition: transform .5s ease,-webkit-transform .5s ease;
}
.tokenimg img:hover{
  transform: scale(1.1);
}
.token_items{
  /* padding: 4px 14px; */
  padding: 4px 14px 20px 14px;
}
.token_items h2 {
  /* color: #000; */
  color: #fff;
  /* font-family: extrabold; */
  /* padding: 8px; */
  margin-bottom: 0;
  font-size: 22px;
}

.token_items p {
  /* color: #000; */
  color: #fff;
  /* padding: 8px; */
  font-size: 14px;
  margin-bottom: 0;
}

.launchpad .token_list {
  background-color: #DEC508;
  border: 1px solid #DEC508;
  border-radius: 5px;
  margin-top: 20px;
  transition: all 0.5s ease;
  margin-bottom: 6px;
  margin-right: 8px;
}

.launchpad .col_token .token_list {
  background-color: transparent;
  border: 1px solid transparent;
padding: 10px 18px;
  border-radius: 5px;
  margin-top: 20px;
  transition: all 0.5s ease;
  margin-bottom: 6px;
  margin-right: 8px;
}

.launchpad .tokenimg {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 220px;
  max-height: 220px;
  overflow: hidden;
  padding: 0;
}

.launchpad .tokenimg img {
  min-height: 200px;
  object-fit: cover;
  max-width: 100%;
}

.newdetail {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  cursor: pointer;
}

.newdetail img {
  min-height: 100vh;
  max-width: 100%;
  object-fit: cover;
  min-width: 100%;
  /* min-height: 100vh;
    max-width: 30%;
    object-fit: contain;
    min-width: 30%;
    margin: auto; */
}
.details img{
  min-height: 100vh !important;
    max-width: 80% !important;
    object-fit: contain !important;
    min-width: 80% !important;
    margin: auto !important;
}
.newdetailsec {
  /* background-color: #fff;
  margin-top: -100px; */
  background-color: transparent;
  /* margin-top: -100px; */
  border: 1px solid #fff;
  border-radius: 8px;

}
.cowa{
  color: #fff !important;
  font-weight: 500;
}
.modal-header {
  /* border-bottom: 1px solid #dee2e659 !important; */
  border-bottom: 1px solid transparent !important;
}

.connect p{
  cursor: pointer;
}
.connect p:hover{
  color:var(--genhover);
}
.recent_post {
  /* background-color: #fff;
  margin-top: 50px;
  padding: 20px; */
  background-color: transparent;
    margin-top: 50px;
    padding: 20px;
    border: 1px solid #fff;
    border-radius: 6px;
}

.vdieo_hide {
  border: 1px solid #eee;
  padding: 0px 40px 40px 40px;
  text-align: center;
}

.form_loist {
  background-color: #fff;
  margin-top: 50px;
  padding: 20px;
}

.newdetailsec h2 {
  color: #fff;
  font-size: 40px;
  padding: 30px 30px 10px 30px;
}

.recent_post h2 {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 10px;
}

.form_loist h2 {
  color: #000;
  font-size: 30px;
  font-weight: 600;
  padding-bottom: 10px;
}

.imgdive {
  display: flex;
  padding: 0 5px 40px 5px;
  font-size: 0;
  flex-flow: row wrap;
  justify-content: center;
  gap: 1px;
  max-width: 800px;
  margin: 0 auto;
}

.imgs {
  margin: 0;
  min-width: 201px;
  flex: 0.67;
  transition: .5s;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-height: 250px;
  max-height: 250px;
}

.imgs img {
  max-width: 100%;
  min-height: 250px;
  object-fit: cover;
}

.recentblogdet {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 20px;
}

.recent_post a {
  color: #BEBDBD !important;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  margin-left: 20px;
  transition: all 0.5s ease;
  word-break: break-word;
}
.newsbackimg{
  max-width: 100% !important;
}
.recent_post a:hover {
  color: var(--genhover) !important;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  margin-left: 20px;
}

.recent_post img {
  width: 80px;
  height: 70px;
  object-fit: contain;
}

.dayscmment {
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 30px;
  cursor: pointer;
}

.dayscmment p {
  color: #818181;
  font-size: 14px;
  margin-right: 10px;
}

.newdetailsec .textconete {
  color: #BEBDBD;
  font-size: 14px;
  margin-right: 10px;
  padding: 0px 30px 30px 30px;
}
.newdetailsec .textconete span{
  color: #B8B8B8 !important;
}
.newdetailsec .textconete strong{
  color: #fff !important;
}
.dayscmment i {
  color: #818181;
  font-size: 14px;
}

/* banner */

.heading {
  font-size: 55px;
}

.banner {
  position: relative;
  overflow: hidden;

}

.bannerimg img {
  width: 100%;
  max-width: 500px;
}

/* feature */
.features-list {
  border: 1px solid transparent;
  border-radius: 14px;
}

.features-list:hover {
  border: 1px solid var(--themeclr);
}

/* roadmap */
h6.roadmapbtn:after {
  position: absolute;
  content: "";
  height: 46px;
  width: 1px;
  background: white;
  top: -31px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
}

h6.roadmapbtn {
  position: relative;
}

.line:after {
  content: "";
  height: 20px;
  width: 20px;
  background: #000000;
  display: block;
  color: var(--themeclr);
  position: absolute;
  border-radius: 50%;
  border: 2px solid white;
  left: 0;
  right: 0;
  margin: auto;
  top: 26%;
  z-index: 1;
}

.car-item:after {
  width: 100%;
  content: "";
  height: 6px;
  background: var(--themeclr);
  position: absolute;
  left: 0;
  right: 0;
  /* top: 26%; */
  top: 29%;
}

.fullinerelative {
  position: relative;
}

.fullline {
  position: absolute;
  height: 6px;
  width: 100%;
  background: #2D2D2D;
  top: 77px;
  border-radius: 10px;
}

.roadmap .owl-item:first-child .car-item:after {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.logogrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 50px;
  place-items: center;
}

.logogrid img {
  width: 10vw;
  max-height: 3vw;
}

.notyetstart .line:after {
  background: var(--themeclr);
  border-color: #817E7E;
  border-width: 3px;
}

.owl-carousel .owl-nav button.owl-next:hover,
.owl-carousel .owl-nav button.owl-prev:hover {
  background: transparent;
}

.notyetstart:after {
  background: transparent;
}

.inprogress:after {
  width: 50%;
}

.car-item h6:first-child {
  margin-bottom: 66px;
}

.car-item h6:first-child {
  margin-bottom: 66px;
}

.car-item {
  text-align: center;
  position: relative;
  width: 100%;
}

.car-item p {
  color: #CBCBCB;
  font-size: 15px;
  padding-right: 15px;
  min-height: 88px;
}

.infos p,
.marketcapital p {
  font-size: 18px;
}

.roadmap .owl-nav button span {
  margin-top: 0 !important;
}

.car-item .line+h6 {
  margin-top: 80px;
}

.car-item h6 {
  color: white;
}

h6.roadmapbtn {
  background: var(--themeclr);
  max-width: 114px;
  padding: 7px;
  border-radius: 20px;
  color: #000;
  margin: auto;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 11px;
}

.slider_sec_1 img {
  max-width: 170px;
  margin: 20px auto;
  transition: all 0.5s ease;
  cursor: pointer;
  /* max-height: 110px;
  min-height: 110px; */
}

.slider_sec_1 .owl-carousel .owl-nav .owl-prev span,
.slider_sec_1 .owl-carousel .owl-nav .owl-next span {
  /* display: none; */
  color: #97afd5;
}

.slider_sec_1 .owl-carousel .owl-nav .owl-prev span,
.slider_sec_1 .owl-carousel .owl-nav .owl-next span {
  color: #97afd5;
  border: 1px solid #97afd5;
  height: 35px;
  width: 35px;
  display: inline-block;
  border-radius: 50%;
  font-size: 35px;
  font-weight: 100;
  line-height: 0.8;
  margin-top: 40px;
}

.slider_sec_1 .owl-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 150px !important;
}

.slider_sec_1 .owl-item div,
.slider_sec_1 .owl-item div a {
  height: 100%;

}

.slider_sec_1 .owl-item .car-item>div {
  padding-top: 40px;
}



/* launchpad */
.bannerbox .innerheading {
  font-size: 45px;
  text-shadow: 0px 8px 2px #5f59592b;
  font-weight: 600;
}

.bordbox {
  /* border: 1px solid #c766fc87; */
  background: url("./Assets/genesis/Launchpad\ box.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  padding: 50px 50px !important;
}
.bordbox1 {
  /* border: 1px solid #c766fc87; */
  /* background: url("./Assets/images/slide/tablebg1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center; */
  border: 1px solid #fff;
  border-radius: 2px;
  padding: 40px 40px !important;
}
.launchbox {
  /* background-color: #0F0F0F;
  border: 1px solid #313131; */
  border: 1px solid #ffffffa3;
  background: url("./Assets/genesis/Mask-group.jpg") no-repeat;
  background-size: 100% 100%;
  /* filter: grayscale(1); */
  position: relative !important;
  z-index: 7;
  /* overflow: hidden; */
}
.colorgray{
  color: #ABABAB;
}
.launchbox.lockedstackss {
  border: unset !important;
  background: url("./Assets/genesis/locked_back.png") no-repeat !important;
  background-size: 100% 100% !important;
}
.launchpad button.chocobtn {
  position: absolute;
  right: 24px;
  top: -17px;
}

.progress {
  background-color: #272727;
  height: 10px;
}

.progress-bar {
  /* background: var(--textclr); */
  /* background: #8243a5; */
  background: #989898;
  border-radius: 5px;
}

.launchbox h6 {
  /* font-family: 'bold'; */
  font-size: 15px;
  font-weight: 500 !important;
  color: #ABABAB !important;
  /* margin-top: 10px; */
}
.launchbox h6.prog_tit{
  color: #fff !important;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.logocont img {
  /* border: 2px solid var(--themeclr);
  border-radius: 50%; */
  height: 70px;
  width: 140px;
  padding: 5px;
  object-fit: contain;
  margin-top: -6px;
}

.logocont span {
  text-transform: uppercase;
  font-size: 20px;
  margin-left: 10px;
  /* font-family: normal; */
}

.launchpad .bgtheme .innerheading {
  text-shadow: 0px 8px 2px #5f59592b;
  font-weight: 600;
}

.calcicon {
  width: 18px;
  filter: hue-rotate(45deg);
}


/* modal */
.modal-backdrop.show {
  opacity: .9;
}

.modal-content {
  /* background: #0F0F0F; */
  background: url("./Assets/genesis/connectwalletbox.png") no-repeat;
  background-size: 100% 100%;
  /* background: #000; */
}

.modal-backdrop {
  /* background-color: #2E2E28; */
  background-color: #000000;
}
.mapsmodal {
  padding-left: 6% !important;
  padding-right: 6% !important;
}
.mapsmodal h6.bold {
  color: #ABABAB !important;
  font-weight: 500 !important;
  margin-top: 12px;
}
.yellowbox {
  /* border: 1px solid var(--themeclr); */
  background: #000000;
  /* border-radius: 15px; */
  background: url("./Assets/genesis/Launchpad\ box.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  padding: 40px 40px !important;
}
.yellowbox .themeclr{
  color: #fff !important;
}

.bannerbox {
  min-height: 181px;
}

.modal .yellowbox.connect.text-center {
  max-width: 185px;
  margin: auto;
}

.modal .yellowbox img {
  max-width: 50px;
  height: auto;
}

.modal-sm {
  max-width: 400px;
  margin: auto;
}

.btn-link.close:hover {
  color: var(--themeclr);
}

.input-group input {
  background: #000 !important;
  border: none;
  border-radius: 8px;
  color: #fff;
  min-height: 48px;

}

.input-group.h-75 input {
  height: 75px;
}

span.input-group-text {
  background: #000;
  border: none;
  border-radius: 8px;
}

.form-control:focus {
  color: #ffff !important;
}

.input-group {
  /* border: 1px solid var(--themeclr); */
  border: 1px solid #ffffff78;
  border-radius: 8px;
}

.input-group input:focus {
  box-shadow: none;
}

.form_loist label {
  color: #5e5e5e;
  font-size: 12px;
}

.labelforme {
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.form_loist .labelforme textarea {
  --webkit-appearance: none !important;
  appearance: none !important;
  border: 1px solid #5e5e5e33 !important;
  margin-top: 10px;
  width: 98%;
}

.form_loist .labelforme textarea:focus {
  outline: none;
}

.form_loist .labelforme input[type="text"] {
  --webkit-appearance: none !important;
  appearance: none !important;
  border: 1px solid #5e5e5e33 !important;
  max-width: 95%;
  min-width: 80%;
  height: 40px;
  width: 100%;
}

.labelformecheck {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.submitfomer {
  background-color: #F6DF2D;
  font-size: 14px;
  color: #000;
  text-decoration: none;
  border-radius: 5px;
  font-weight: 500;
  padding: 12px 14px !important;
  height: 50px;
  margin-top: 30px;
  border: 1px solid #F6DF2D;
  transition: all 0.5s ease;
}

.vdieo_hide iframe {
  max-width: 100%;
  width: 550px;
  max-height: 300px;
  height: 300px;
  min-height: 300px;
}

.submitfomer:hover {
  background-color: transparent;
  color: #000;
}

.labelformecheck p {
  color: #5e5e5e;
  font-size: 14px;
  margin-bottom: 0;
  margin-left: 10px;
}

.form_loist .labelforme input:focus {
  outline: none;
}

.inputfoe {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

/* footer */
.footer {
  border-top: 1px solid #80808078;
  padding-top: 25px;
  padding-bottom: 10px;
  margin-top: 54px;
  position: relative;
}

.footer .textclr {
  /* font-family: light; */
  color: #fff;
  font-size: 12px;
}

.footer a svg {
  /* color: #74E842; */
  color: var(--greens);
  font-size: 18px;
  margin-left: 10px;
}

.footer a svg:hover {
  /* color: #ab3ee7; */
  color: var(--navhover);
}

.footer ul li {
  list-style-type: none;
  display: inline-block;
}

.footer ul li {
  margin-left: 10px;
  margin-right: 10px;
}

.footer ul li:first-child {
  margin-left: 0;
}

.footer ul li span {
  color: var(--textclr);
  font-size: 25px;
}



@media(min-width:1601px) {

  .car-item p {
    min-height: 78px;
  }

  .line:after {
    top: 23%;
  }

}

@media only screen and (min-width: 1400px) and (max-width: 1699px)  {
  .faq .accordion-collapse.collapse.show {
    height: 600px;
    min-height: 600px;
    max-height: 600px;
  }
}

/* @media only screen and (min-width: 1200px) and (max-width: 1399px)  {
.faq .accordion-collapse.collapse.show {
  height: 690px;
  min-height: 690px;
  max-height: 690px;
}
} */

@media(max-width:1199px) {
  .smallp {
    max-width: 100%;
  }

  .news .itemsfles {
    min-height: 60vh;
  }

  .news .itemsfles h2 {
    font-size: 40px !important;
  }

  .news .items {
    height: 60vh;
  }

  .news .owl-carousel .owl-nav {
    bottom: 50px;
  }
}

@media(max-width:992px) {
  .news .itemsfles h2 {
    font-size: 40px;
  }

  .news .itemsfles {
    min-height: 50vh;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px)  {
  .faq .accordion-collapse.collapse.show {
    height: 490px;
    min-height: 490px;
    max-height: 490px;
  }
  }

@media(max-width:991px) {
  .userbtn.smallclaim{
    min-width: 120px !important;
   }
  .navbar-collapse {
    position: absolute;
    background: black;
    width: 100%;
    top: 87px;
    left: 0px;
    padding-left: 40px;
    padding-top: 20px;
    padding-bottom: 30px;
  }

  .news .items {
    height: 90vh;
    background-size: 100%;
  }

  .news .itemsfles {

    width: 100%;
  }

  .news .itemsfles p {
    width: 80%;
  }

  .news .itemsfles h2 {
    font-size: 36px;
  }

  .navbar-collapse a {
    max-width: max-content;
    max-width: fit-content;
  }

  .bannerbox .innerheading {
    font-size: 30px;
  }

  .header .navbar-nav a {
    margin: 0;
  }

  .heading {
    font-size: 40px;
  }

  .bannerimg img {
    max-width: 390px;
  }
}

@media(max-width:767px) {

  .bordbox {
    /* border: 1px solid #c766fc87; */
    border: 1px solid #ffffff87;
    background-image: linear-gradient(#1a1a1a, #101010, #070707) !important;

    background:unset;
    /* background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center; */
    padding: 100px 40px !important;
  }

  .infos p,
  .marketcapital p {
    font-size: 16px;
  }

  .news .owl-carousel .owl-nav {
    bottom: 0px;
  }

  .news .itemsfles {
    min-height: 41vh;
  }

  .news .items {
    height: 90vh;
    background-size: 100% 100%;
  }

  .navbar-brand img {
    height: 40px;
  }

}

@media(max-width:575px) {
  .bordbox {
    padding: 70px 20px 30px 20px !important;
}
 .userbtn.smallclaim{
  min-width: 120px !important;
 }
 .bordbox1 {
   padding: 20px 20px !important;
 }
  .footer .textclr {
    text-align: center;
  }

  .footer .col-12 {
    text-align: center !important;
  }
}

.logos_sec {
  max-width: 80%;
  margin: 0 auto;
}

@media(max-width:575px) {
  .newdetailsec {
    margin-top: -10px;
  }

  .logogrid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media(max-width:425px) {
  .slider_sec_1 img {
    max-width: 99px;
    margin: 0px auto;
  }

  footer {
    text-align: center !important;
    margin: 0 auto !important;
  }

  .newdetail {
    min-height: unset;
    max-height: unset;
  }

  .newdetail img {
    min-height: unset;
  }

  .items .newdetail img {
    min-height: 100vh;
  }

  .news .itemsfles p {
    font-size: 14px;
  }

  .newdetailsec h2 {
    font-size: 28px;
  }

  .connect p {
    font-size: 12px;
  }

  .news .itemsfles h2 {
    font-size: 24px !important;
  }

  .news .owl-carousel .owl-nav button.owl-next,
  .news .owl-carousel .owl-nav button.owl-prev {
    font-size: 22px;
    width: 32px;
  }

  .news .owl-carousel .owl-nav {
    left: 0;
    right: 0;
  }
}

@media(min-width:1800px) {
  .heading {
    font-size: 75px;
  }
}

.news .Editor_pick.firstsec .token_list {
  height: 100%;
}

.newdetailsec h2 {
  font-weight: 900;
  background: #0000008f;
}

.newdetailsec .textconete b {
  font-size: 16px;
}

.launchpad .noticelink {
  /* color: #838383 !important; */
  color: #ffff !important;
  font-size: 18px;

}

.launchpad .menu_list {
  position: relative;

}

.launchpad .notice_link {
  position: absolute;
  right: 0px;
}



/* .Editor_pick.firstsec .col_token{
  height: 100%;

} */